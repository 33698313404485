export const environment = {
    production: true,
  
    baseUrl: 'https://admin.ifma-qa.devbyopeneyes.com',
    ssoLoginUrl: 'https://loginifmaorg-sandbox.azurewebsites.net/?redirectUrl=',
    ssoLogoutUrl: 'https://loginifmaorg-sandbox.azurewebsites.net/Account/LogOff/?redirectUrl=',
    apiUrl: 'https://api.ifma-qa.devbyopeneyes.com/api',
    covidUrl: 'https://covid.amsn.thisistheprogress.com',
    dcmUrl: 'https://dcm.amsn.thisistheprogress.com',
    connectorUrl: 'https://connector.ifma-qa.devbyopeneyes.com',
    CookieDomainUrl: 'ifma-qa.devbyopeneyes.com',
    authUrl: 'https://mysat.ifma-qa.devbyopeneyes.com',
    authUrlReal: 'https://myassessments.ifma-qa.devbyopeneyes.com',
    cndURL: 'https://assets.ifma-qa.devbyopeneyes.com/',
    TestMode: true,
    env: 'prod',
  };
